<template>
  <CRow>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showCopiedToClipboard">
          <CToast          
          :show.sync="showCopiedToClipboard"
          color="info"
          >
            Copied to clipboard!
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showDatabaseUpdateStarted">
          <CToast          
          :show.sync="showDatabaseUpdateStarted"
          color="success"
          >
            Database updated started! This process can take up to 5 minutes.
          </CToast>
        </div>   
      </template>         
    </CToaster>         
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol col="2">
              <CButton class="float-left" disabled variant="ghost" color="dark">Improvement Items</CButton>
            </CCol>
            <CCol col="4">
              <ASelect
                :options="lenses"
                placeholder="Select Lens to filter..."
                @change="setFilterLens($event)"
                class='float-left col-xl-6'
                />            
                <ASelect
                :options="pillars"
                placeholder="Select Pillar to filter..."
                @change="setFilterPillar($event)"
                class='float-left col-xl-6'
                />      
            </CCol>
            <CCol col="4"/>              
            <CCol col="2">
              <CButton class="float-right" variant="outline" @click="updateImprovements" color="info" v-c-tooltip="{ content: 'Starts a background process to scrape all improvements from AWS' }">Update DB</CButton>
            </CCol>
          </CRow>
        </CCardHeader>      
        <CCardBody>       
          <CAlert
            :show.sync="dismissCountDown"
            closeButton
            color="success"
            fade
          >
            Database updated started! This process can take up to 5 minutes.            
          </CAlert>            
          <CDataTable
            hover
            striped
            sorter            
            cleaner
            column-filter
            items-per-page-select
            :column-filter-value="{ 'lens_alias': selectedLens, 'pillar_id': selectedPillar }"
            :sorter-value="{ column: 'lens_alias', asc: 'true' }"
            :items="items"
            :fields="fields"
            :items-per-page="20"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            @page-change="pageChange"
            :loading=loadingImprovementItemsTable
          >                         
              <td slot="lens_alias" slot-scope="{item}" >
                  {{ lenses[item.lens_alias] }}
              </td>               

              <td slot="improvement_guidance_url" slot-scope="{item}"> 
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <span class="d-inline-block text-truncate" style="max-width: 250px;">
                    {{ item.improvement_guidance_url }}                  
                  </span>  
                  <div class="custom-tooltip" v-show="showTooltip">
                    {{ item.improvement_guidance_url }}                  
                  </div>
                </div>        
              </td> 

              <td slot="choice_name" slot-scope="{item}">
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <span class="d-inline-block text-truncate" style="max-width: 400px;">
                  {{ item.choice_name }}
                  </span>
                  <div class="custom-tooltip" v-show="showTooltip">
                    {{ item.choice_name }}
                  </div>
                </div>                   
              </td> 

              <td slot="pillar_id" slot-scope="{item}" >
                  {{ pillars[item.pillar_id] }}
              </td> 

              <td slot="improvement_view" slot-scope="{item}"> 
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <CButton v-on:click="viewImprovement(item)" :id="item.improvement_id"  color="dark" >
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
                  <div class="custom-tooltip custom-tooltip-left" v-show="showTooltip">
                    Opens the scraped Improvement Item without HTML.
                  </div>
                </div>                                  
              </td>  

              <td slot="improvement_view_html" slot-scope="{item}"> 
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <CButton v-on:click="viewImprovementHtml(item.improvement_cloud, item.lens_alias, item.pillar_id, item.choice_id)" :id="item.improvement_id"  color="dark">
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
                  <div class="custom-tooltip custom-tooltip-left" v-show="showTooltip">
                    Opens the scraped HTML block. Will be loaded from the DB when opened.
                  </div>
                </div>                   
              </td>              

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      size="lg"
      :title="viewImprovementItem.choice_name"
      color="dark"
      :show.sync="viewImprovementModal"
    >
    <CRow col="12">
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <strong>Question ID / Choice ID</strong>
          </CCardHeader>
          <CCardBody>
            {{ viewImprovementItem.question_id }} / {{ viewImprovementItem.choice_id }}
          </CCardBody>
        </CCard>
      </CCol>      
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <strong>Lense / Pillar</strong>
          </CCardHeader>
          <CCardBody>
            {{ lenses[viewImprovementItem.lens_alias] }} / {{ pillars[viewImprovementItem.pillar_id] }}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      <CCard>
        <CCardHeader>
          <strong>Improvement Guidance URL</strong>
        </CCardHeader>
        <CCardBody>
          <CRow col="12">
            <CCol col="10">
              <CInput                      
                placeholder="No URL available"
                v-model="viewImprovementItem.improvement_guidance_url"
                disabled
              >
                <template #prepend-content><CIcon name="cil-link"/></template>
              </CInput>
            </CCol>
            <CCol col="1">
              <CButton  
                :id="viewImprovementItem.improvement_guidance_url"
                :href="viewImprovementItem.improvement_guidance_url"
                :disabled="viewImprovementItem.improvement_guidance_url === undefined"
                target="_blank"
                color="info"
                style="display: inline-grid;">
                <span class="material-icons md-18">link</span>
              </CButton>
            </CCol>
            <CCol col="1">
              <CButton 
                :id="viewImprovementItem.improvement_guidance_url"
                :disabled="viewImprovementItem.improvement_guidance_url === undefined"
                v-on:click="copyToClipBoard(viewImprovementItem.improvement_guidance_url)" 
                color="info"
                style="display: inline-grid;">                      
                  <span class="material-icons md-18">content_copy</span>
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>        
      <div slot="footer" class="w-100">
          <CButton @click="viewImprovementModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
      </div>
    </CModal> 
    <CModal
      size="xl"
      :title="viewImprovementHtmlItem.choice_name"
      color="dark"
      :show.sync="viewImprovementHtmlModal"
    >
      <template>
        <span class="m-2">
          <i>The HTML preview design/layout does not match the final look in the report!</i>
        </span>
      </template>
      <p class="mt-2"> 
        <quill-editor
          label="Description"
          ref="myQuillEditor"
          :value="viewImprovementItemHtmlPlain"                
          :options="editorOption"
          :disabled="true"
        /> 
      </p>
      <div slot="footer" class="w-100">
          <CButton @click="viewImprovementModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
      </div> 
    </CModal> 
  </CRow>
</template>

<script>
import ImprovementService from '@/services/improvement.services';
import LenseService from '@/services/lense.services';
import ASelect from '@/components/forms/ASelect.vue';

export default {
  components: {
    ASelect,
  },  
  data() {
    return {
      items: [],
      fields: [
        { key: 'choice_name', label: 'Name', _style:'width:20%' },
        { key: 'lens_alias', label: 'Lens', _style:'width:20%' },
        { key: 'pillar_id', label: 'Pillar', _style:'width:20%' },
        { key: 'improvement_guidance_url', label: 'URL', _style:'width:10%', sorter: false, filter: false },
        { key: 'improvement_view', label: 'Item',  _style: 'width:1%', sorter: false, filter: false },
        { key: 'improvement_view_html', label: 'HTML',  _style: 'width:1%', sorter: false, filter: false }
      ],
      lenses: {},
      pillars: {},
      loadingImprovementItemsTable: true,
      viewImprovementModal: false,
      viewImprovementHtmlModal: false,
      viewImprovementItem: {},
      viewImprovementHtmlItem: {},
      viewImprovementItemHtmlPlain: "",
      activePage: 1,
      selectedLens: "",
      selectedPillar: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      showCopiedToClipboard: false,
      showDatabaseUpdateStarted: false,
      showTooltip: false,
      editorOption: {
        modules: {
          toolbar: false
        },
        placeholder: 'No HTML found!',
        readOnly: true,
      },

    };
  },

  methods: {
   updateImprovements() {
     this.showDatabaseUpdateStarted = false;
     ImprovementService.triggerImprovementsUpdater().then(
        response => { },
        error => {   
          console.log(error)   
        }
      )
     this.showDatabaseUpdateStarted = true;
    },    
    setFilterLens(event){      
      this.selectedLens = event.target.selectedOptions[0].dataset.key
    },
    setFilterPillar(event){      
      this.selectedPillar = event.target.selectedOptions[0].dataset.key
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    } ,
    viewImprovement(improvement) {
      this.viewImprovementItem = improvement
      this.viewImprovementModal = true
    },
    copyToClipBoard(textToCopy){
      this.showCopiedToClipboard = false;    
      navigator.clipboard.writeText(textToCopy);      
      this.showCopiedToClipboard = true;
    },
    viewImprovementHtml(cloud, lens_alias, pillar_id, choice_id) {
      ImprovementService.getImprovementByLensPillarChoice(cloud, lens_alias, pillar_id, choice_id).then(
        response => { 
          this.viewImprovementHtmlItem = response
          this.viewImprovementHtmlModal = true
          this.viewImprovementItemHtmlPlain = this.b64DecodeUnicode(this.viewImprovementHtmlItem.improvement_guidance_html)
        },
        error => {   
          console.log(error)   
        }
      )
    },
    b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    },
  },

  async created() {
    this.selectedCloud = this.$route.params.cloud
  },
  mounted() {
    LenseService.getLenses().then(
      response => { 
        this.lenses = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    LenseService.getPillars().then(
      response => { 
        this.pillars = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    ImprovementService.getImprovements('aws').then(
      response => { 
        this.items = response.items;
        this.loadingImprovementItemsTable = false;
      },
      error => {   
        console.log(error)   
      }
    )
  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>

